<template>
  <ion-page>
    <div class="background--login" />
    <ion-header class="ion-no-border">
      <ion-toolbar
        class="toolbar"
        color="transparent"
      >
        <ion-item
          slot="start"
          color="transparent"
          lines="none"
        >
          <ion-img
            class="web-logo"
            :src="webLogo"
          />
        </ion-item>
        <ion-item
          slot="end"
          color="transparent"
          class="text--white"
          lines="none"
        >
          <ion-label />
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div
        v-if="loading"
        class="mt-20"
      >
        <ion-progress-bar
          type="indeterminate"
        />
        <div class="mt-20">
          <ion-text style="font-size: 20px">
            {{ $t('Loading') }}....
          </ion-text>
        </div>
      </div>
      <div v-else-if="noData">
        <ion-text
          class="text--white"
          style="font-size: 20px"
        >
          {{ $t('Invalid Token') }}.....
        </ion-text>
      </div>
      <div
        v-else
        class="container form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <div
          class="text-left"
          style="margin-bottom: 30px;"
        >
          <span class="text--white text-64">{{ $t('Complete') }} <span class="text--primary">{{ $t('Registration') }}</span></span>
        </div>
        <div v-if="selectedView === '1'">
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              {{ $t('emailAddress') }}
            </ion-label>
            <ion-input
              id="username"
              v-model="usersData.email"
              enterkeyhint="done"
              autocomplete="email"
              type="email"
              required
            />
          </ion-item>
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              {{ $t('fullName') }}
            </ion-label>
            <ion-input
              id="username"
              v-model="usersData.name"
              enterkeyhint="done"
              type="text"
              required
            />
          </ion-item>
          <ion-item lines="none">
            <ion-label
              class="label"
              position="floating"
            >
              {{ $t('phoneNumber') }} (+46)
            </ion-label>
            <ion-input
              id="username"
              v-model="usersData.phone"
              autocomplete="phone"
              type="phone"
              enterkeyhint="done"
              required
            />
          </ion-item>
          <ion-row class="ion-no-padding">
            <ion-col style="margin-right: 5px;">
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  {{ $t('address') }}
                </ion-label>
                <ion-input
                  id="username"
                  v-model="usersData.myAddress.street"
                  type="text"
                  required
                  enterkeyhint="done"
                />
              </ion-item>
            </ion-col>
            <ion-col style="margin-left: 5px;">
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  {{ $t('House nr.') }}
                </ion-label>
                <ion-input
                  id="username"
                  v-model="usersData.myAddress.propertyNumber"
                  type="number"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col style="margin-right: 5px;">
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  {{ $t('City') }}
                </ion-label>
                <ion-input
                  id="username"
                  v-model="usersData.myAddress.city"
                  type="text"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
            <ion-col style="margin-left: 5px;">
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  {{ $t('Post Code') }}
                </ion-label>
                <ion-input
                  id="username"
                  v-model="usersData.myAddress.postCode"
                  type="text"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col
              size="8"
              style="margin-right: 5px;"
            >
              <ion-item lines="none">
                <ion-label style="padding-bottom: 10px;">
                  {{ $t('appLanguage') }}
                </ion-label>
                <ion-select
                  v-model="usersData.language"
                  style="margin-top: 7px;"
                  placeholder="Chose one"
                >
                  <ion-select-option value="EN">
                    English
                  </ion-select-option>
                  <ion-select-option value="SE">
                    Svenska
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col
              size="4"
              style="margin-left: 5px;"
            />
          </ion-row>
          <ion-item
            lines="none"
            color="primary"
            button
            :disabled="!usersData.phone"
            @click="selectedView = '2'"
          >
            <ion-text
              class="text--bold"
              style="color: #2c3036; margin: 0 auto;"
            >
              {{ $t('save') }}
            </ion-text>
          </ion-item>
        </div>
        <div
          v-else-if="selectedView === '2'"
        >
          <form
            class="login-form form--dark"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          >
            <ion-item lines="none">
              <ion-label
                class="label"
                position="floating"
              >
                {{ $t('password') }}
              </ion-label>
              <ion-input
                id="username"
                v-model="password"
                autocomplete="password"
                type="password"
                required
                enterkeyhint="done"
              />
            </ion-item>
            <ion-item lines="none">
              <ion-label
                class="label"
                position="floating"
              >
                {{ $t('Confirm Password') }}
              </ion-label>
              <ion-input
                id="username"
                v-model="repeatPassword"
                autocomplete="password"
                enterkeyhint="done"
                type="password"
                required
              />
            </ion-item>
          </form>
          <div style="min-height: 25px;">
            <password-meter
              :password="password"
              @score="passwordScore = $event.score"
            />
            <transition
              name="fade1"
              mode="out-in"
            >
              <ion-label v-if="message.message">
                <p :class="message.class">
                  {{ message.message }}
                </p>
              </ion-label>
            </transition>
          </div>
          <ion-row>
            <ion-col style="margin-right: 5px;">
              <ion-item
                lines="none"
                color="dark"
                detail="false"
                button
                @click="selectedView = '1'"
              >
                <ion-text
                  class="text--bold"
                  style="margin: 0 auto;"
                >
                  {{ $t('goBack') }}
                </ion-text>
              </ion-item>
            </ion-col>
            <ion-col style="margin-left: 5px;">
              <ion-item
                lines="none"
                color="primary"
                detail="false"
                button
                :disabled="!password || !repeatPassword || password !== repeatPassword"
                @click="registerUser"
              >
                <ion-spinner
                  v-if="loading"
                  style="margin: 0 auto;"
                  class="text--dark"
                  name="crescent"
                />
                <ion-text
                  v-else
                  class="text--bold text--dark"
                  style="margin: 0 auto;"
                >
                  {{ $t('Sign Up') }}
                </ion-text>
              </ion-item>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonProgressBar } from '@ionic/vue'
import webLogo from '../assets/web/web-logo.png'
import PasswordMeter from 'vue-simple-password-meter'

export default {
  components: {
    IonProgressBar,
    PasswordMeter
  },
  data () {
    return {
      webLogo,
      loading: false,
      noData: false,
      message: '',
      selectedView: '1',
      token: '',
      password: '',
      repeatPassword: '',
      passwordScore: 0,
      usersData: {
        language: 'SE',
        myAddress: {
          city: '',
          street: '',
          country: '',
          houseNumber: ''
        }
      }
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.getUsersData(this.$route.params)
    console.log(this.$route.params)
  },
  methods: {
    getUsersData (token) {
      console.log(token.token)
      this.$store.dispatch('completeRegistrationGetUsersData', token.token).then(response => {
        console.log(response.data)
        const data = response.data
        if (data) {
          this.usersData = response.data
          this.token = token.token
          this.loading = false
        } else {
          this.loading = false
          this.noData = true
        }
      })
    },
    registerUser () {
      if (this.passwordScore <= 3) {
        this.message = { message: this.$t('Password is too weak'), class: 'error' }
        setTimeout(() => { this.message = {} }, 2000)
        this.loading = false
      } else {
        const data = this.usersData
        data.password = this.password
        this.$store.dispatch('completeRegistrationRegisterUser', { token: this.token, data }).then(response => {
          if (response) {
            this.$router.push('/login')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ion-progress-bar {
    --background: gray;
}
.toolbar {
  color: white;
  .web-logo {
      max-width: 150px;
  }
}
    .text-64 {
        font-size: 54px;
    }

@media only screen and (min-width: 1000px) {
.container {
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
}
.background--login::after {
    background-size: 100% auto;
}
.toolbar {
  padding-left: 20vw;
  padding-right: 20vw;
}
}
.error {
  color: #eb445a;
}

</style>